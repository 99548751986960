import getNodePositionOffsets from "../helpers/getNodePositionOffsets.js";
import roundCoords from "../helpers/roundCoords.js";

import getSelectedNodes from "../helpers/workflow/nodes/getSelectedNodes.js";
import deleteNode from "../helpers/workflow/nodes/deleteNode.js";

import deepCopy from "../helpers/deepCopy.js";
import getNodeType from "../helpers/workflow/nodes/getNodeType.js";

import nodeCanBeDeleted from "../helpers/workflow/nodes/nodeCanBeDeleted.js";
import nodeCanBeCopied from "../helpers/workflow/nodes/nodeCanBeCopied.js";

import createNode from "../helpers/workflow/nodes/createNode.js";
import systemAvailableNodes from "../nodes/index.js";

export default {
    props: {
        nodes: {
            type: Object,
            required: true
        },
        availableNodes: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            nodeIsMoved: false,
            nodeInFocus: false,
            nodeMouseDownTarget: null,
            nodeMouseDownTargetNode: null,
            nodesNeedsToBeMounted: false,
            mountedNodes: [],
        };
    },
    computed: {
        selectedNodes() {
            return getSelectedNodes(this.nodes);
        },
        fullAvailableNodes() {
            return {
                ...this.availableNodes,
                ...systemAvailableNodes
            };
        }
    },
    methods: {
        addToMountedNodes(node) {
            if(typeof node !== "object") {
                console.error("Cant add to mounted nodes");
                return;
            }

            this.mountedNodes.push(node.id);
        },
        removeFromMountedNodes(node) {
            if(typeof node !== "object") {
                console.error("Cant remove from mounted nodes");
                return;
            }

            this.mountedNodes.filter((mountedNodeId) => mountedNodeId !== node.id);
        },
        getNodeType(node) {
            if(typeof node !== "object") {
                console.error("Cant check node type");
                return;
            }

            return getNodeType(node, this.fullAvailableNodes);
        },
        nodeCanBeDeleted(node) {
            if(typeof node !== "object") {
                console.error("Cant check node cant be deleted");
                return;
            }

            return nodeCanBeDeleted(node, this.fullAvailableNodes);
        },
        nodeCanBeCopied(node) {
            if(typeof node !== "object") {
                console.error("Cant check node cant be deleted");
                return;
            }

            return nodeCanBeCopied(node, this.fullAvailableNodes);
        },
        deselectAll() {
            const updatedNodes = {...this.nodes};

            for(const nodeId in this.selectedNodes) {
                updatedNodes[nodeId].selected = false;
                this.$emit('deselectNode', updatedNodes[nodeId]);
            }

            this.$emit('update:nodes', updatedNodes);
        },
        clickToConnectionLine(event, isKeyM) {
            if(event === 'mousedown' && isKeyM) return this.isMoveToConnects = true
            this.isMoveToConnects = false
        },
        nodeClick(event, node) {
            if(!event || !node) {
                console.error('Wrong params');
                return;
            }

            if(event.button !== 0) return;

            if(!event.shiftKey && !node.selected && !event.altKey) {
                this.deselectAll();
            }

            node.selected = !event.altKey;

            if(node.selected) {
                this.$emit('selectNode', node);
            } else {
                this.$emit('deselectNode', node);
            }

            this.startMovingSelectedNodes(event, node);
        },
        deleteNode(node) {
            if(!node) {
                console.error('Wrong params');
                return;
            }

            if(!this.nodeCanBeDeleted(node)) return;

            const updatedNodes = {...this.nodes};
            const nodeCopy = deepCopy(node);

            if(deleteNode(node.id, updatedNodes)) {
                this.$emit('deleteNode', nodeCopy);
                this.$emit('update:nodes', updatedNodes);
            }
        },
        startMovingSelectedNodes(event, node) {
            if(!event || !node) {
                console.error('Wrong params');
                return;
            }

            if(event.button !== 0) return;

            this.nodeMouseDownTarget = event.target;
            this.nodeMouseDownTargetNode = node;
        },
        endMovingSelectedNodes(event) {
            if(!event) return;

            if(!this.nodeMouseDownTargetNode || event.button !== 0) return;

            this.resetMovingNodeTargets();
            this.resetNodeMoving();
        },
        resetMovingNodeTargets() {
            this.nodeMouseDownTarget = null;
            this.nodeMouseDownTargetNode = null;
        },
        resetNodeMoving() {
            this.nodeIsMoved = false;
        },
        moveSelectedNodes(event) {
            if(!event) return;

            if(!this.nodeMouseDownTarget || !this.nodeMouseDownTargetNode || !event) return;

            const targetBounds = event.target.getBoundingClientRect();
            const rootBounds = this.$refs.root.getBoundingClientRect();

            const targetNodeComponent = this.$refs[`node-${this.nodeMouseDownTargetNode.id}`][0];

            const boundsOffset = [
                (targetBounds.left - rootBounds.left) / this.rightOptions.scale,
                (targetBounds.top - rootBounds.top) / this.rightOptions.scale
            ];

            const offsets = getNodePositionOffsets(this.nodeMouseDownTargetNode.id, this.selectedNodes);
            const updatedNodes = {...this.nodes};

            for(const nodeId in this.selectedNodes) {
                const node = this.nodes[nodeId];
                const offset = offsets[nodeId] ?? [0,0];

                const clearPosition = [
                    boundsOffset[0] + offset[0] + (event.offsetX + this.position[0]) / this.rightOptions.scale - targetNodeComponent.$el.clientWidth/2,
                    boundsOffset[1] + offset[1] + (event.offsetY  - this.position[1]) / this.rightOptions.scale - targetNodeComponent.$el.clientHeight/2
                ];

                const position = this.rightOptions.gridBinding ? roundCoords(clearPosition, this.rightOptions.cellSize) : clearPosition;

                if(node.position[0] !== position[0] || node.position[1] !== position[1]) {
                    if(!this.nodeIsMoved) {
                        this.takeSnapshot();
                    }

                    this.nodeIsMoved = true;

                    updatedNodes[node.id] = {
                        ...node,
                        position,
                    }

                    this.$emit('moveNode', node);
                }
            }

            this.$emit('update:nodes', updatedNodes);
        },
        addNode(nodeData) {
            if(!nodeData) {
                console.error('Cant addNode, wrong params');
                return;
            }

            if(!(nodeData.type in this.fullAvailableNodes)) {
                console.error('Cant add node, available node type doesnt exists');
                return;
            }

            this.takeSnapshot();

            const newNode = createNode(nodeData, this.fullAvailableNodes);

            if(typeof newNode === "object") {
                let updatedNodes = {
                    ...this.nodes,
                };
                updatedNodes[newNode.id] = newNode;

                this.$emit('update:nodes', updatedNodes);
                this.$emit('addNode', newNode);

                return newNode;
            }
        },

        async addNodeForReplace(nodeData) {
            if(!nodeData) {
                console.error('Cant addNode, wrong params');
                return;
            }

            if(!(nodeData.type in this.fullAvailableNodes)) {
                console.error('Cant add node, available node type doesnt exists');
                return;
            }
            // this.takeSnapshot();
                        
            const newNode = createNode({type: nodeData.type, selected: nodeData.selected}, this.fullAvailableNodes);

            newNode.position = nodeData.replacementNode.position
            if(nodeData.replacementNode.overwrittenNodeType) newNode.overwrittenNodeType = nodeData.replacementNode.overwrittenNodeType

            this.takeSnapshot();
            // this.lockSnapshots();

            if(typeof newNode === "object") {

                this.addNode(newNode);

                // this.unlockSnapshots();

                const parentNodes = this.connectionNodePreparedInfo
                    .filter(item => item.endNodeId === nodeData.replacementNode.id)
                    .map(item => item.startNodeId);

                // send data to admin 
                this.$emit('replacementNode', {newNode, replacementNode: nodeData.replacementNode, parentNodes});
            
                // close palete
                this.closeNodesList();
                this.replaceNodeData = this.nodeData = null;
            }
        },
    },
};