import roundCoords from "../helpers/roundCoords.js";
import selectCollidedNodes from "../helpers/workflow/selectCollidedNodes.js";

export default {
    data() {
        return {
            guideIsOpen: false,
            availableNodesIsOpen: false,
            addNodeFutureInfo: null,
            selectStart: null,
            selectEnd: null,
            replaceNodeData: null,
            nodeData: null
        };
    },
    computed: {
        centerEditorScreenPosition() {
            return [
                (this.position[0] + this.$refs.root.clientWidth/2)/this.rightOptions.scale,
                (-this.position[1] + this.$refs.root.clientHeight/2)/this.rightOptions.scale,
            ];
        },
    },
    methods: {
        openNodesListForReplace(data) {
            this.availableNodesIsOpen = true;
            this.replaceNodeData = data;
        },
        openNodesList() {
            this.availableNodesIsOpen = true;
        },
        closeNodesList() {
            this.availableNodesIsOpen = false;
            this.addNodeFutureInfo = null;
        },
        toggleGuideVisibility() {
            this.guideIsOpen = !this.guideIsOpen;
        },
        outputMouseLeave() {
            if(this.tempConnection) {
                this.tempConnection.outputMouseLeave = true;
            }
        },
        setNodeAddFutureInfo(event, startNode, startNodeOutputName) {
            if(!event || !startNode || !startNodeOutputName) {
                console.error('Cant set node future info, wrong params');
                return;
            }

            const rootBounds = this.$refs.root.getBoundingClientRect();
            const eventBounds = event.target.getBoundingClientRect();

            const eventPosition = [
                (eventBounds.left - rootBounds.left) + event.offsetX,
                (eventBounds.top - rootBounds.top) + event.offsetY,
            ];

            const position =  [
                (this.position[0] + eventPosition[0])/this.rightOptions.scale,
                (-this.position[1] + eventPosition[1])/this.rightOptions.scale,
            ];
            const roundedPosition = this.rightOptions.gridBinding ? roundCoords(position, this.rightOptions.cellSize) : position;

            const finalPosition = this.tempConnection && this.tempConnection.outputMouseLeave ? roundedPosition : [
                startNode.position[0] + this.rightOptions.cellSize * 5, startNode.position[1]
            ];

            this.addNodeFutureInfo = {
                startNode,
                position: finalPosition,
                startNodeOutputName,
            };
        },
        setFutureInfoOpenNodeList(event, startNode, startNodeOutputName) {
            this.setNodeAddFutureInfo(event, startNode, startNodeOutputName);
            this.openNodesList();
        },
        addNodeFromUi(nodeData) {

            if(!nodeData) return;

            // for replace node
            if(this.replaceNodeData) {
                this.nodeData = nodeData

                return this.visiblePopupReplace = !this.visiblePopupReplace
            }
 
            this.takeSnapshot();
            this.lockSnapshots();

            const newNode = this.addNode(nodeData);

            if(this.addNodeFutureInfo) {
                newNode.position = this.addNodeFutureInfo.position;

                this.$nextTick(() => {
                    this.lockSnapshots();

                    if(this.fullAvailableNodes[newNode.type].input) {
                        this.addConnection(this.addNodeFutureInfo.startNode, this.addNodeFutureInfo.startNodeOutputName, newNode);
                    }

                    this.closeNodesList();
                    this.unlockSnapshots();
                });
            } else {
                const position = [...this.centerEditorScreenPosition];
                newNode.position = this.rightOptions.gridBinding ? roundCoords(position, this.rightOptions.cellSize) : position;
            }

            this.unlockSnapshots();
        },
        selectCollidedNodes() {
            if(!this.selectStart || !this.selectEnd) {
                console.error('Cant select collided nodes');
                return;
            }

            const startRightCoords = [
                (this.position[0] + this.selectStart[0])/this.rightOptions.scale,
                (-this.position[1] + this.selectStart[1])/this.rightOptions.scale
            ];

            const endRightCoords  = [
                (this.position[0] + this.selectEnd[0])/this.rightOptions.scale,
                (-this.position[1] + this.selectEnd[1])/this.rightOptions.scale
            ];

            const updatedNodes = selectCollidedNodes([startRightCoords, endRightCoords], this.nodes);

            this.$emit('update:nodes', updatedNodes);
        },
        startSelecting(event) {
            if(!event) {
                console.error('Cant start select, wrong params');
                return;
            }

            const rootBounds = this.$refs.root.getBoundingClientRect();
            const eventBounds = event.target.getBoundingClientRect();

            this.selectStart = [
                (eventBounds.left - rootBounds.left) + event.offsetX,
                (eventBounds.top - rootBounds.top) + event.offsetY,
            ];
            this.selectEnd = [...this.selectStart];
        },
        selectMove(event) {
            if(!event) {
                console.error('Cant move select, wrong params');
                return;
            }

            if(!this.selectStart) return;

            const rootBounds = this.$refs.root.getBoundingClientRect();
            const eventBounds = event.target.getBoundingClientRect();

            this.selectStart = [
                (eventBounds.left - rootBounds.left) + event.offsetX,
                (eventBounds.top - rootBounds.top) + event.offsetY,
            ];
        },
        resetSelection() {
            this.selectStart = null;
            this.selectEnd = null;
        },
        endSelection(event) {
            if(!event) return;

            if(!this.selectStart || !this.selectEnd || event.button !== 0) return;

            this.selectCollidedNodes();

            this.resetSelection();
        },
        dragStartNode(eventData) {
            if(!eventData) {
                console.error('Cant start drag node, wrong params');
                return;
            }

            eventData.event.dataTransfer.setData('type', eventData.type);
        },
        dragCellDropNode(event) {
            if(!event) return;

            const nodeType = event.dataTransfer.getData('type');

            if(!nodeType || !( nodeType in this.fullAvailableNodes)) {
                console.error('Cant drag node output drop, wrong params');
                return;
            }

            const nodeInfo = this.fullAvailableNodes[nodeType];

            const eventBounds = event.target.getBoundingClientRect();
            const rootBounds = this.$refs.root.getBoundingClientRect();

            const position = [
                (this.position[0] - (eventBounds.left - rootBounds.left) + event.offsetX)/this.rightOptions.scale,
                (-this.position[1] - (eventBounds.top - rootBounds.top) + event.offsetY)/this.rightOptions.scale,
            ];

            this.addNode({
                type: nodeType,
                displayName: nodeInfo.name,
                selected: false,
                position: this.rightOptions.gridBinding ? roundCoords(position, this.rightOptions.cellSize) : position,
            });
        },
        dragNodeOutputDrop(eventData) {
            const nodeType = eventData.event.dataTransfer.getData('type');

            if(!eventData || !nodeType || !(nodeType in this.fullAvailableNodes)) {
                console.error('Cant drag node output drop, wrong params');
                return;
            }

            this.takeSnapshot();
            this.lockSnapshots();

            const nodeInfo = this.fullAvailableNodes[nodeType];

            const position = [
                eventData.node.position[0] + this.rightOptions.cellSize * 5,
                eventData.node.position[1],
            ];

            const newNode = this.addNode({
                type: nodeType,
                displayName: nodeInfo.name,
                selected: false,
                position: this.rightOptions.gridBinding ? roundCoords(position, this.rightOptions.cellSize) : position,
            });

            if(nodeInfo.input) {
                this.$nextTick(() => {
                    this.lockSnapshots();
                    this.addConnection(eventData.node, eventData.outputName, newNode);
                    this.unlockSnapshots();
                });
            }

            this.unlockSnapshots();
        },
        setScaleFromUi(direction) {
            if(!direction) {
                console.error('Cant set scale from ui');
                return;
            }

            const newScale = this.rightOptions.scale + direction * this.rightOptions.scaleStep;
            this.setScale(newScale);
        }
    }
}